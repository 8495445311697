import React, { setGlobal } from 'reactn'
// import './snipcart.css'
import './snipcartoverride.scss'
// import ReactGA4 from 'react-ga4'
// import TagManager from 'react-gtm-module'
import PaymentVisibility from './paymentVisibility'
import API from '@aws-amplify/api';
import { getUserID } from './auth/auth';
import { Helmet } from 'react-helmet';

const aa = require('search-insights');
const LogRocket = require('logrocket')
var flatten = require('flat')
const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;
const cartStages = [
  'cart-content',
  'login',
  'order-infos',
  'billing-address',
  'shipping-address',
  'shipping-method',
  'payment-method',
  'order-confirm',
]

const snipcartURL = process.env.GATSBY_ACTIVE_ENV == 'production' ? "/snipcart.js" : "/snipcartd.js"
const isSSR = typeof window === "undefined"

function gtag() {
  console.log("GALL GTAG", arguments)
  window.dataLayer.push(arguments);
}

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.isSnipcartReady = false
    this.productsQueue = []

    this.handleProductClick = this.handleProductClick.bind(this)
    this.handleItemAdding = this.handleItemAdding.bind(this)
    this.cartOpened = this.cartOpened.bind(this)
    this.cartClosed = this.cartClosed.bind(this)
    this.orderCompleted = this.orderCompleted.bind(this)
    this.orderError = this.orderError.bind(this)
    this.paymentFailed = this.paymentFailed.bind(this)
    this.discountApplied = this.discountApplied.bind(this)

    this.itemUpdated = this.itemUpdated.bind(this)
    // this.pageChanged = this.pageChanged.bind(this)
    this.itemRemoved = this.itemRemoved.bind(this)
    this.SnipcartReady = this.SnipcartReady.bind(this)
    this.sendCartEvent = this.sendCartEvent.bind(this)
    // this.cart = null

    aa("init", {
      appId: appId,
      apiKey: searchKey,
    });

  }

  componentDidMount() {
    console.log("SNIPCARt init")

    if (!isSSR) {
      window.dataLayer = window.dataLayer || [];
    }
    document.addEventListener('snipcart.ready', this.SnipcartReady)
  }

  componentWillUnmount() {
    document.removeEventListener('snipcart.ready', this.SnipcartReady)

  }

  handleProductClick(e) {
    if (
      e.target.classList.contains('snipcart-add-item') &&
      this.isSnipcartReady
    ) {
      var item = JSON.parse(e.target.getAttribute('dataset'))
      this.productsQueue.push(item)
    } else {
      return
    }
  }

  sendInsight(eventName, item) {
    try {
      if (item && item.hasOwnProperty('metadata') && item.metadata != undefined && item.metadata.hasOwnProperty('searchHit')) {
        if (item.metadata.searchObjectID && item.metadata.searchQueryID) {
          aa('convertedObjectIDsAfterSearch', {
            userToken: getUserID(),
            eventName: eventName,
            index: process.env.GATSBY_ALGOLIA_INDEX_NAME,
            queryID: item.metadata.searchQueryID,
            objectIDs: [item.metadata.searchObjectID]
          });
        }
      }
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Send Insight to Angolia", e)
    }
  }

  handleItemAdding(item) {
    console.log('ITEM ADDING', item)
    this.sendInsight("Product Added to Cart", item)

    this.itemAdded(item)

    if (window.navigator.onLine) {
      console.log('ADDING - ONLINE')
      return
    }
    // TWW RE_ENABLE LATER
    // console.log('ADDING - ITEM', item)
    // if (item) {
    //   console.log('ADDING - PUSHING', item)
    //   this.productsQueue.push(item)
    //   // this.preventDefault(item)
    // }

  }

  // dequeueProducts() {
  //   console.log('DEQUEUE PRODUCTS')
  //   // Snipcart.ready.then(function() {
  //     // Snipcart.api.cart.start().then(() => {
  //     var itemsToAdd = []
  //     if (this.productsQueue.length > 0) {
  //       console.log('ITEMS TO DEQUEUE')
  //       for (var i in this.productsQueue) {
  //         var product = this.productsQueue[i]
  //         console.log('DEQUEU ', product)
  //         if (product) {
  //           itemsToAdd.push(product)
  //         }
  //       }
  //       console.log('DEQUEUE - ADD ITEM')
  //       Snipcart.api.cart.items.add(itemsToAdd).then(function(items) {
  //         this.productsQueue = []
  //       })
  //     }
  //   // })
  // }

  createProductFromItems(items) {
    var products = []
    var price = 0
    items.forEach(item => {
      products.push(this.createProductFromItem(item))
      price = price + (item.price * item.quantity)
    });
    console.log("NEW ITEMS", products)
    return {
      items: products,
      price
    }
  }

  createProductFromItem(item) {
    var itemToAdd = {
      item_id: item.id,
      item_name: item.name,
      item_brand: 'INGMARSON',
      // description: item.description,
      currency: 'GBP',
      quantity: item.quantity,
      price: item.price,
      quantity: item.quantity ? item.quantity : 1,
    }

    if (item.metadata && item.metadata.itemLocation && item.metadata.collection) {
      itemToAdd['index'] = item.metadata.itemLocation
      itemToAdd['item_list_name'] = item.metadata.collection
      itemToAdd['item_list_id'] = item.metadata.collection
    }
    // console.log("GA4CREATE", itemToAdd)
    return itemToAdd
  }

  sendCartEvent(eventAction) {
    try {
      console.log('SENDING CART EVENT', eventAction)
      console.log('SENDING CART EVENT - WINDOW.DATA LAYER', window.dataLayer)
      // window.dataLayer.push({ event: eventAction })
      gtag('event', action)
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Error", e)
    }
  }
  0
  sendCartItemEvent(items, eventData, action, eventAction) {
    console.log("FLATTEN", items, action, eventAction)
    console.log('SENDING CART EVENT - WINDOW.DATA LAYER', window.dataLayer)
    try {

      console.log("GA SENDING ACTION", action)
      console.log("GA SENDING DATA", eventData)
      // ReactGA4.event(
      //   action,
      //   // label: item.name,
      //   eventData
      // )
      gtag('event', action, eventData);
      // window.dataLayer.push({
      //   event: action,
      //   // label: item.name,
      //   ecommerce: eventData
      // }
      // )
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Error", e)
    }
  }

  sendCartAnalytics(item, action, eventAction) {
    console.log("SEND CART ANALYTICS", item, action, eventAction)
    try {
      const product = this.createProductFromItem(item)
      this.sendCartItemEvent([product], {
        currency: "GBP",
        discount: 0.00,
        value: Number(product.price * product.quantity),
        items: [product],
        // cart: Snipcart.store.getState().cart
      }, action, eventAction)
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Error", e)
    }
  }

  cartOpened() {
    try {
      console.log("Snipcart1", Snipcart)
      // console.log("Snipcart2", Snipcart.api)
      // console.log("Snipcart3", Snipcart.cart)
      var snipcartState = Snipcart.store.getState()
      var products = snipcartState.cart.items.items

      var { items, price } = this.createProductFromItems(products)
      this.sendCartItemEvent(items, {
        currency: "GBP",
        discount: 0.00,
        value: Number(price),
        items: items,
        // cart: snipcartState.cart
      }, 'view_cart', "VIEW CART")
      this.informBackend("CART_OPENED", {})
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Cart Open", e)
    }
  }

  cartClosed() {
    try {
      var snipcartState = Snipcart.store.getState()
      var products = snipcartState.cart.items.items
      var { items, price } = this.createProductFromItems(products)
      this.sendCartItemEvent(items, {
        currency: "GBP",
        discount: 0.00,
        value: Number(price),
        items: items,
        // cart: snipcartState.cart
      }, 'cart_closed', "CART CLOSED")
      this.informBackend("CART_CLOSED", {})
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Cart Open", e)
    }
  }

  discountApplied(discount) {
    try {
      var snipcartState = Snipcart.store.getState()
      var products = snipcartState.cart.items.items

      var { items, price } = this.createProductFromItems(products)
      this.sendCartItemEvent(items, {
        discount: discount,
        currency: "GBP",
        value: Number(price),
        items: items,
        // cart: snipcartState.cart
      }, 'discount_applied', "DISCOUNT APPLIED")

    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log DISCOUNT", e)
    }
  }

  informBackend(type, webhookData) {
    try {
      // Call API To Register Event
      const data = {
        body: {
          type: type,
          data: webhookData
        },
        headers: {
          "Content-Type": "application/json"
        }
      };
      console.log("CALL API", webhookData, API)
      API
        .post('api', '/snipcartClient', data)
        .then(response => {
          console.log("INFORM BACKEND COMPLETE", response)
        })
        .catch(error => {
          console.log("INFORM BACKEND ERROR", error.response);
        });
    } catch (e) {
      // LogRocket.captureException(e)
      console.log("FAILED TO Inform backend of data", e)
    }
  }

  paymentFailed(error) {
    try {
      var snipcartState = Snipcart.store.getState()
      var products = snipcartState.cart.items.items

      var { items, price } = this.createProductFromItems(products)
      this.sendCartItemEvent(items, {
        error: error,
        currency: "GBP",
        value: Number(price),
        discount: 0.00,
        items: items,
        // cart: snipcartState.cart
      }, 'payment_failed', "PAYMENT FAILED")

      this.informBackend("PAYMENT_FAIL", error)

    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Payment Failed", e)
    }
  }

  orderError(error) {
    try {
      // Add Items
      console.log("ORDer eRROR", error)
      var snipcartState = Snipcart.store.getState()
      var products = snipcartState.cart.items.items

      var { items, price } = this.createProductFromItems(products)
      this.sendCartItemEvent(items, {
        error: error,
        discount: 0.00,
        currency: "GBP",
        value: Number(price),
        items: items,
        // cart: snipcartState.cart
      }, 'order_error', "ORDER ERROR")
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Error", e)
    }
  }

  orderCompleted(order) {

    try {
      var { items, price } = this.createProductFromItems(order.items.items)
      var coupon = ""
      var discount = 0
      // console.log("ORDer DISCOUNTX", order.discounts)
      if (order.discounts && order.discounts.items && order.discounts.items.length > 0) {
        // console.log("ORDER DISCOUNT", order.discounts.items[0])
        var discountItem = order.discounts.items[0]
        coupon = discountItem.code
        discount = Math.abs(discountItem.value)
      }
      this.sendCartItemEvent(items, {
        transaction_id: order.token,
        coupon: coupon,
        discount: Number(discount),
        currency: "GBP",
        value: Number(order.total),
        shipping: Number(order.shippingDetails.cost),
        items: items,
        order: order
      }, 'purchase', "ORDER COMPLETE")
      // Set User Data
      gtag('set', 'user_data', {
        "email": order.email ? order.email : "",
        "address": {
          "street": order.billingAddress.address1 ? order.billingAddress.address1 : "",  
          "city": order.billingAddress.city ? order.billingAddress.city : "",
          "postal_code": order.billingAddress.postalCode ? order.billingAddress.postalCode : "",
          "country": order.billingAddress.country ? order.billingAddress.country : "",
        }
      })
    } catch (e) {
      LogRocket.captureException(e)
      console.log("FAILED TO Log Order Complete", e)
    }
  }

  itemUpdated(item) {
    this.sendCartAnalytics(item, 'update', 'Item Updated in Cart')
  }


  preventClick(e) {
    e.preventDefault()
    console.log('The prevent link was clicked.')
  }

  itemRemoved(item) {
    try {
      this.sendCartAnalytics(item, 'remove_from_cart', 'Item Removed from Cart')
      this.informBackend("ITEM_REMOVED", item)
    } catch (e) {
      console.log("FAILED TO REMOVE ITEM", e)
    }
  }

  async itemAdded(item) {
    try {
      console.log("SNIPCART ITEM ADDED", item)
      // console.log("CARTXXXX1", Snipcart.store.getState())
      // console.log("CARTXXXX2", this.cart)      
      this.sendCartAnalytics(item, 'add_to_cart', 'Item Added to Cart')
      this.informBackend("ITEM_ADDED", item)
    } catch (e) {
      console.log("SNIPCART FAILED TO ADD ITEM", e)
    }
  }

  SnipcartReady() {
    console.log("SNIPCART - WINDOW", window.Snipcart)
    console.log('SNIPCART READY')
    // this.cart = Snipcart.store.getState()
    Snipcart.ready.then(function () {
      Snipcart.api.session.setLanguage('en', {
        actions: {
          edit: 'Edit',
          cancel: 'CANCEL',
          continue_shopping: 'CONTINUE SHOPPING',
          back_to_checkout: 'Back to checkout',
          checkout: 'CHECKOUT',
          apply: 'APPLY',
          type_address: 'Type your address',
          use_this_address: 'Use this address',
          back_to_store: 'BACK',
          close_cart: 'Close cart',
          show: 'Show',
          hide: 'Hide',
        },
        header: {
          title_cart_summary: 'CHECKOUT',
          loading: 'Loading...',
        },
        item: {
          quantity: 'QUANTITY',
          quantity_short: 'Qty',
          decrement_quantity: 'Decrement quantity',
          increment_quantity: 'Increment quantity',
          remove_item: 'Remove item',
        },
        cart: {
          subtotal: 'Subtotal',
          shipping_taxes_calculated_at_checkout:
            'Shipping will be calculated at checkout.',
          loading: 'LOADING CART ... ',
          secured_by: 'PROVIDED BY SNIPCART',
          summary: 'Order summary',
          empty: 'Your cart is empty.',
          invoice_number: 'Invoice number',
          view_detailed_cart: "View detailed cart"
        },
        order: {
          loading: "We're getting your order details...",
        },
        discount_box: {
          promo_code: 'PROMO CODE',
          promo_code_placeholder: 'PROMO CODE',
          promocode_applied: 'Promotion applied',
        },
        address_form: {
          name: 'FULL NAME',
          email: 'Email',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          city: 'City',
          province: 'County',
          postalCode: 'Postcode',
          country: 'Country',
          dont_see_address: "I don't see my address",
        },
        billing: {
          title: 'Billing',
          address: 'Address Line 1',
          continue_to_shipping: 'Continue to shipping',
          use_different_shipping_address: 'Use different shipping address',
        },
        customer: {
          information: 'Customer information',
        },
        customer_dashboard: {
          my_account: 'My account',
          ordered_on: 'Ordered on',
          total: 'Total',
          status: 'Status',
          order: 'Order %{invoice_number}',
          order_details: 'Order details',
          loading: 'Loading...',
          no_orders: 'No orders found.',
          sign_out: 'SIGN OUT',
          show_more_orders:
            'Show %{smart_count} more order |||| Show %{smart_count} more orders',
        },
        signin_form: {
          signin: 'SIGN IN',
          dont_have_an_account: "Don't have an account?",
          email: 'Email',
          password: 'Password',
          forgot_your_password: 'Forgot your password?',
          close_form: 'Go back',
        },
        register_form: {
          register: 'REGISTER',
          already_have_an_account: 'Already have an account?',
          email: 'EMAIL',
          password: 'PASSWORD',
          confirm_password: 'Confirm Password',
        },
        guest_checkout: {
          or: 'Or',
          continue_as_a_guest: 'Continue as a guest',
        },
        shipping: {
          title: 'Shipping',
          shipping_to: '',
          address: 'Shipping address',
          method: 'Shipping method',
        },
        payment: {
          form: {
            card_label: 'Credit card',
            card_number: 'Card number',
            card_expiration: 'MM/YY',
            card_cvv: 'CVV',
            card_postal_code: 'Postal code',
          },
          title: 'Payment',
          continue_to_payment: 'Continue to payment',
          credit_card: 'Credit card',
          place_order: 'Place order',
          preparing_payment_session: 'Preparing payment...',
          processing_payment: 'Processing payment...',
          checkout_with: 'Checkout with',
          checkout_with_method: 'Checkout with %{method}',
          method_icon: '%{method} icon',
          no_payment: 'No payment is required for this order.',
          methods: {
            card: 'Credit card',
            paypal: 'PayPal',
            paypal_express_checkout: 'PayPal',
            apple_pay: 'Apple Pay',
            bancontact: 'Bancontact',
            belfius: 'Belfius',
            eps: 'Electronic Payment Standard',
            giropay: 'Giropay',
            ideal: 'iDEAL',
            ing_home_pay: 'ING Home Pay',
            kbc: 'KBC',
            klarna_pay_later: 'Klarna pay later',
            klarna_slice_it: 'Klarna monthly financing',
            p24: 'Przelewy24',
            sepa_debit: 'SEPA bank transfer',
            sofort: 'Sofort bank transfer',
          },
        },
        discounts: {
          title: 'Discounts',
        },
        cart_summary: {
          taxes: "VAT",
          taxes_included_in_price: "VAT is included in the price.",
          total: 'Total',
          subtotal: 'Subtotal',
          shipping: 'Shipping',
          discount: 'Discounts',
          quantity: 'x',
        },
        errors: {
          default: 'An error occured, try again later or contact us.',
          promo_code_is_invalid: "This promo code isn't valid",
          promocode_already_in_cart: 'This promo code is already applied',
          promocode_isnt_applicable: "This promo code isn't valid",
          promo_code_is_expired: 'This promo code has expired',
          stringEmpty: 'This field is required',
          required: 'This field is required',
          invalid_expiry_year_past: 'Card is expired',
          email: 'Please provide a valid email address',
          error_payment_items_are_invalid: {
            title: 'Some items in your cart are invalid',
            description: 'Please review your order, or try again later.',
          },
          order_validation: {
            crawling_failed: {
              title: "Your order couldn't be processed.",
              description:
                'No payment has been processed. Please contact store owner for details.',
            },
            invalid_items: {
              title:
                'The price of some products in your cart may have changed.',
              description:
                'Try removing and adding them to your cart. Please contact store owner for details.',
            },
            out_of_stock: {
              title:
                'Some products in your cart are now out of stock for the desired quantity.',
              description:
                'Try revise the quantity added to the cart. Please contact store owner for details.',
            },
          },
          shipping: {
            title: 'Unable to calculate shipping',
            description: 'Try again later, or contact us for support.',
          },
          payment_failed: {
            title: 'Unable to process payment',
            description: 'Try again later, or contact us for support.',
          },
          payment_authorization: {
            title: 'Unable to process payment',
            default: 'Please review billing and payment information.',
          },
          customer_exists: 'This email is already used.',
          customer_password_missmatch: 'The two passwords must match.',
          invalid_credentials: 'Invalid email or password.',
          no_shipping_rates_found: {
            title: 'No shipping method available for your order',
            description: 'Please review shipping address or contact us.',
          },
        },
        customer_orders: {
          loading: 'Loading...',
        },
        confirmation: {
          thank_you_for_your_order: 'Thank you for your order',
          async_confirmation_notice:
            'Your order has been placed and is currently processing. You will receive a confirmation shortly.',
        },
        checkout: {
          shipping_taxes_calculated_when_address_provided:
            'Shipping will be calculated when an address is be provided.',
        },
      })
      Snipcart.api.theme.customization.registerPaymentFormCustomization({
        input: {
          backgroundColor: 'white',
          color: '#303030',
          border: '3px solid #9e9e9e',
          fontSize: '16px',
          placeholder: {
            color: 'black',
          },
        },
        label: {
          color: '#fff',
          fontSize: '20px',
        }
      });
      console.log('SETTING SNIPCART TO READY ')

      // this.setState({ isSnipcartReady: true });
      // console.log("SET SNIPCART TO READY ", snipcartReady)

    })
    this.isSnipcartReady = true
    var snipcartState = Snipcart.store.getState()
    console.log("SNIPCART CART CURRENTLY ", snipcartState)
    Snipcart.events.on('theme.routechanged', (routesChange) => {
      console.log("ROUTE CHANGED", routesChange)
      try {
        var snipcartState = Snipcart.store.getState()
        if (routesChange.to === "/cart") {
          var products = snipcartState.cart.items.items
          console.log("PRODUCTSXXX", products)
          var { items, price } = this.createProductFromItems(products)
          this.sendCartItemEvent(items, {
            currency: "GBP",
            discount: 0.00,
            value: Number(price),
            items: items,
            // cart: snipcartState.cart
          }, 'view_cart', "OPEN CART")
          if (snipcartState && snipcartState.cart && snipcartState.cart.shippingDetails) {
            const shippingMethod = snipcartState.cart.shippingDetails.method ? snipcartState.cart.shippingDetails.method : ""
            if (shippingMethod == "INTERNATIONAL SHIPPING") {
              this.sendCartItemEvent(items, {
                currency: "GBP",
                discount: 0.00,
                value: Number(price),
                shipping_tier: shippingMethod,
                items: items,
                // cart: snipcartState.cart
              }, 'fraud_attempt_cart', "FRAUD ATTEMPT")
              setGlobal({ showPayment: false })
            } else {
              this.sendCartItemEvent(items, {
                currency: "GBP",
                discount: 0.00,
                value: Number(price),
                shipping_tier: shippingMethod,
                items: items,
                // cart: snipcartState.cart
              }, 'non_fraud_attempt_cart', "FRAUD ATTEMPT")
              setGlobal({ showPayment: true })
            }

          }
          // this.sendCartEvent('CART OPENED')
        } else if (routesChange.to === "/checkout") {
          // var snipcartState =  Snipcart.store.getState()        
          var products = snipcartState.cart.items.items
          console.log("WOHOO CHECKOUTOUT")
          console.log("PRODUCTSXXX", products)
          var { items, price } = this.createProductFromItems(products)
          this.sendCartItemEvent(items, {
            currency: "GBP",
            discount: 0.00,
            value: Number(price),
            items: items,
            // cart: snipcartState.cart
          }, 'begin_checkout', "OPEN CHECKOUT")
          if (snipcartState && snipcartState.cart && snipcartState.cart.shippingDetails) {
            const shippingMethod = snipcartState.cart.shippingDetails.method ? snipcartState.cart.shippingDetails.method : ""
            if (shippingMethod == "INTERNATIONAL SHIPPING") {
              this.sendCartItemEvent(items, {
                currency: "GBP",
                discount: 0.00,
                value: Number(price),
                shipping_tier: shippingMethod,
                items: items,
                // cart: snipcartState.cart
              }, 'fraud_attempt_checkout', "FRAUD ATTEMPT")
              setGlobal({ showPayment: false })
            } else {
              this.sendCartItemEvent(items, {
                currency: "GBP",
                discount: 0.00,
                value: Number(price),
                shipping_tier: shippingMethod,
                items: items,
                // cart: snipcartState.cart
              }, 'non_fraud_attempt_checkout', "FRAUD ATTEMPT")
              setGlobal({ showPayment: true })
            }

          }

        }
      } catch (e) {
        setGlobal({ showPayment: true })
        LogRocket.captureException(e)
        console.log("FAILED TO Log Shipping Method on route change", e)
      }
    })

    Snipcart.events.on('cart.created', (cartState) => {
      // alert("CREATED")
      console.log(cartState);
    });

    Snipcart.events.on('shipping.selected', (shippingMethod) => {
      console.log("SHIPPING SELECTED", shippingMethod);
      try {
        var snipcartState = Snipcart.store.getState()
        var products = snipcartState.cart.items.items
        console.log("PRODUCTSXXX", products)
        var { items, price } = this.createProductFromItems(products)
        this.sendCartItemEvent(items, {
          currency: "GBP",
          discount: 0.00,
          value: Number(price),
          shipping_tier: shippingMethod,
          items: items,
          // cart: snipcartState.cart
        }, 'add_shipping_info', "SHIPPING SELECTED")
        // this.sendCartEvent('SHIPPING METHOD SELECTED')
        if (shippingMethod.method == "INTERNATIONAL SHIPPING") {
          this.sendCartItemEvent(items, {
            currency: "GBP",
            discount: 0.00,
            value: Number(price),
            shipping_tier: shippingMethod,
            items: items,
            // cart: snipcartState.cart
          }, 'fraud_attempt', "FRAUD ATTEMPT")
          setGlobal({ showPayment: false })
        } else {
          this.sendCartItemEvent(items, {
            currency: "GBP",
            discount: 0.00,
            value: Number(price),
            shipping_tier: shippingMethod,
            items: items,
            // cart: snipcartState.cart
          }, 'nonfraud_attempt', "FRAUD ATTEMPT")
          setGlobal({ showPayment: true })
        }
      } catch (e) {
        setGlobal({ showPayment: true })
        LogRocket.captureException(e)
        console.log("FAILED TO Log Shipping Method", e)
      }

    });

    Snipcart.events.on('item.adding', this.handleItemAdding)
    Snipcart.events.on('item.removed', this.itemRemoved)
    Snipcart.events.on('cart.opened', this.cartOpened)
    Snipcart.events.on('cart.closed', this.cartClosed)
    Snipcart.events.on('cart.confirmed', this.orderCompleted)
    Snipcart.events.on('cart.confirm.error', this.orderError)
    Snipcart.events.on('payment.failed', this.paymentFailed)
    Snipcart.events.on('discount.applied', this.discountApplied)


    Snipcart.events.on('summary.checkout_clicked', () => {
      try {
        var snipcartState = Snipcart.store.getState()
        var shippingMethod = ""
        try {
          shippingMethod = snipcartState.cart.shippingDetails.method ? snipcartState.cart.shippingDetails.method : ""
        } catch (e) {
          // Ignore Error
        }
        var products = snipcartState.cart.items.items
        console.log("PRODUCTSXXX", products)
        var { items, price } = this.createProductFromItems(products)
        
        this.sendCartItemEvent(items, {
          currency: "GBP",
          discount: 0.00,
          value: Number(price),
          shipping_tier: shippingMethod,
          items: items,
          // cart: snipcartState.cart
        }, 'checkout_clicked', "CHECKOUT CLICKED")
      } catch (e) {
        LogRocket.captureException(e)
        console.log("FAILED TO Log Checkout Clicked", e)
      }
    });
    Snipcart.events.on('item.updated', this.itemUpdated)
    // Snipcart.events.on('page.change', this.pageChanged)
    // TODO Snipcart.api.configure('show_continue_shopping', true);
    // TODO Snipcart.api.configure('split_firstname_and_lastname', true);
  }

  isSnipcartLoaded() {
    return !!window.Snipcart
  }
  loadSnipJs() {
    return this.addBodyElem('script', {
      async: true,
      src: 'https://cdn.snipcart.com/themes/v3.4.0/default/snipcart.js'
    })
  }

  loadCart() {
    return this.addBodyElem(
      'div',
      {
        id: 'snipcart',
        'class': 'snipcartHidden',
        'data-api-key': this.props.apiKey,
        'data-config-modal-style': 'side'
      },
      this.customiseCart()
    )
  }

  customiseCart() {
    return this.getCartHeaderNew() + this.getBillingSection() + this.addPhoneNumber() + this.addBillingHeader();
    // this.getCart() + this.getCartHeader() + this.addCartSummaryFees() + this.addShippingHeader() + this.addBillingHeader() + this.addCustomOrderFields() + this.getAddressFields()  //this.getShippingAddress() +//+ this.addPaymentSection() //+ this.getCheckout() //+ this.getShippingMethods
    // return this.getCartHeader() + this.addShippingHeader() + this.addBillingHeader() +  this.addCustomOrderFields() + this.getAddressFields()  //this.getShippingAddress() +//+ this.addPaymentSection() //+ this.getCheckout() //+ this.getShippingMethods
  }
  addBodyElem(tag, attrs, innerHTML) {
    return new Promise((resolve, reject) => {
      var el = document.createElement(tag)
      el.onload = resolve
      el.onerror = reject

      var keys = Object.keys(attrs)

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i]
        el.setAttribute(key, attrs[key])
      }

      if (innerHTML) {
        el.innerHTML = innerHTML
      }
      document.body.appendChild(el)
    })
  }

  render() {
    return <div>
      <Helmet lang="en">
        <script async defer src={snipcartURL} type="text/javascript" />
      </Helmet>
      <PaymentVisibility />
    </div>
  }
}

export default Cart
